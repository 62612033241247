import Link from "next/link";
import { generateItemPart } from "../services/urls";

const Image = (props) => {
    const { url, description } = props;
    return (
        <img
            className="list-image rounded-md object-cover w-full h-full"
            src={url}
            srcSet={`${url?.replace("/sm", "/xxs")} 276w, ${url?.replace(
                "/sm",
                "/xs"
            )} 384w, ${url} 512w`}
            size="(max-width: 600px) 276px, (max-width: 1200px) 384px, 512px"
            alt={description}
            loading="lazy"
            key={url}
            style={{ minHeight: 128, minWidth: 128 }}
        />
    );
};

export function MasonryItem(props) {
    const { description, small, baseUrl } = props;
    return (
        <div
            onClick={(e) => {
                props.onItemClick?.(props);
                e.preventDefault();
                e.stopPropagation();
            }}
            className={
                "aspect-square rounded-md overflow-hidden m-3 sm:m-2 lg:m-4 w-1/3 sm:w-1/4 md:w-1/5 bg-gray-100 group-[.secondary]:bg-white  hover:opacity-75 cursor-pointer dark:bg-gray-900 dark:group-[.secondary]:bg-gray-900  " +
                (small ? "max-w-[200px]" : "")
            }
        >
            {baseUrl ? (
                <Link href={generateItemPart(baseUrl, props)}>
                    <a>
                        <Image {...props} />
                        <span className="hidden">{description}</span>
                    </a>
                </Link>
            ) : (
                <Image {...props} />
            )}
        </div>
    );
}
