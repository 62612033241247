import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ArrowDownTrayIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { Spinner } from "./Spinner";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export default function DownloadButton(props) {
    return (
        <div className=" inline-flex shadow-sm w-full">
            <button
                type="button"
                disabled={props.loading}
                onClick={() => props.onDownloadSvg?.()}
                className={`border disabled:bg-gray-500 disabled:border-gray-500 border-secondary-dark hover:border-secondary-darkest w-full relative inline-flex justify-center items-center ${props.onlySvg ? 'rounded-md' : 'rounded-l-md'} bg-secondary-dark px-3 py-2 text-sm text-center font-semibold text-white hover:bg-secondary-darkest focus:z-10`}
            >
                {props.loading ? (
                    <div className="w-6 h-6 -mt-1 -mb-1 -mr-2">
                        <Spinner />
                    </div>
                ) : (
                    <ArrowDownTrayIcon className="fill-white h-4 w-4" />
                )}
                <div className="flex-1">Download SVG</div>
            </button>
            {!props.onlySvg && <Menu as="div" className="relative -ml-px block">
                <Menu.Button
                    disabled={props.loading}
                    className="border disabled:bg-gray-500 disabled:border-l-gray-600 disabled:border-gray-500 border-secondary-dark hover:border-secondary-darkest border-l-secondary-darkest relative inline-flex items-center rounded-r-md bg-secondary-dark px-2 py-2 text-white hover:bg-secondary-darkest focus:z-10 disabled:text-gray-300"
                >
                    <span className="sr-only">Open options</span>
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="cursor-pointer absolute bottom-full right-0 z-10 -mr-1 my-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        onClick={() => props.onDownloadSvg?.()}
                                        className={classNames(
                                            active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                            "block px-4 py-2 text-sm"
                                        )}
                                    >
                                        Download SVG
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        onClick={() => props.onDownloadPng?.()}
                                        className={classNames(
                                            active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                            "block px-4 py-2 text-sm"
                                        )}
                                    >
                                        Download PNG
                                    </a>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>}
        </div>
    );
}
