/* eslint-disable @next/next/no-img-element */
import { kebabCase } from "lodash";
import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { Spinner } from "./Spinner";
import DownloadButton from "./DownloadButton";
import { downloadImage } from "../services/utils";
import axios from "axios";
import Auth from "./Auth";
import { PaintBrushIcon, RectangleStackIcon } from "@heroicons/react/20/solid";
import CopyLinkButton from "./CopyLinkButton";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
} from "@heroicons/react/24/solid";
import Link from "next/link";
import { PremiumDialogBody } from "./PremiumDialog";
import { generateCollectionUrl } from "../services/urls";
import { useTheme } from "next-themes";

export function Item(props) {
    const { item, collection } = props;
    const [myCollection, setMyCollection] = useState(collection);
    const [myItem, setMyItem] = useState(item);
    const [isDownloadingPNG, setIsDownloadingPNG] = useState(false);
    const [isDownloadingSVG, setIsDownloadingSVG] = useState(false);
    const [isLoading, setIsLoading] = useState(!!myItem.svg);

    const cancelButtonRef = useRef(null);

    useEffect(() => {
        setMyItem(item);
    }, [item]);

    const downloadSVG = () => {
        setIsDownloadingSVG(true);
        const url = "/api/download/" + myItem.image;
        downloadImage(url, "svg")
            .catch((err) => {
                if (err?.response?.status === 400) {
                    props?.onAuthNeeded?.();
                    window.umami?.track("Download SVG Auth Needed", { item: myItem });
                } else if (err?.response?.status === 401) {
                    props?.onPremiumNeeded?.();
                }
            })
            .finally(() => {
                setIsDownloadingSVG(false);
            });

        window.umami?.track("Download SVG", { item: myItem });
    };

    const downloadPNG = () => {
        setIsDownloadingPNG(true);
        const url = "/api/download/" + myItem.image + "?png=1";
        downloadImage(url, "png")
            .catch((err) => {
                if (err?.response?.status === 400) {
                    props?.onAuthNeeded?.();
                    window.umami?.track("Download PNG Auth Needed", { item: myItem });
                } else if (err?.response?.status === 401) {
                    props?.onPremiumNeeded?.();
                }
            })
            .finally(() => {
                setIsDownloadingPNG(false);
            });

        window.umami?.track("Download PNG", { item: myItem });
    };

    useEffect(() => {
        async function fetch() {
            if (item?.image && !item.url) {
                const result = await axios.get(`/api/image/${item.image}`);
                const fetchedItem = result?.data?.item;
                if (fetchedItem) {
                    setMyItem(fetchedItem);
                }
            }
        }
        fetch();
    }, [item]);
    const { theme } = useTheme();
    return (
        <div className="sm:flex bg-gray-100 dark:bg-gray-900" style={{ minHeight: 560 }}>
            <div className="flex-shrink-0 p-3 sm:p-6 bg-white dark:bg-gray-800 sm:w-8/12 relative">
                <div
                    className="image-display w-full h-full relative flex items-center justify-center"
                    style={
                        isLoading
                            ? {
                                  background: `url('${myItem.url}') no-repeat`,
                                  backgroundPosition: "center",
                                  backgroundPositionY: "top",
                                  backgroundSize: "contain",
                              }
                            : { background: theme === "dark" ? "#1f2937" : "#fff" }
                    }
                >
                    <img
                        width={400}
                        style={{ maxHeight: "100%" }}
                        placeholder={myItem.url}
                        src={myItem.svg || myItem.url}
                        alt={myItem.description}
                        onLoad={() => setIsLoading(false)}
                        key={myItem.url}
                    />
                </div>
                {isLoading && (
                    <div className="flex-shrink absolute left-10 bottom-10">
                        <Spinner />
                    </div>
                )}

                <div className="absolute top-4 left-4 sm:top-6 sm:left-5">
                    <CopyLinkButton
                        link={`https://vectorart.ai${
                            myCollection
                                ? generateCollectionUrl(
                                      kebabCase(myCollection),
                                      myItem
                                  )
                                : "/" + myItem.image
                        }`}
                    />
                </div>

                {myItem.others && (
                    <>
                        <div className="absolute bottom-6 text-center w-full flex items-center justify-center pr-12">
                            {[props.item, ...myItem.others]
                                .slice(0, 8)
                                .map((other, i) => (
                                    <div
                                        key={other.image}
                                        className={
                                            "rounded overflow-hidden border-2 mx-1" +
                                            (other.image === myItem.image
                                                ? " border-2 border-secondary"
                                                : "") +
                                            (i >= 6 ? " hidden sm:block" : "")
                                        }
                                    >
                                        <img
                                            onClick={() => {
                                                setMyCollection(
                                                    other.collection ||
                                                        collection
                                                );
                                                setMyItem({
                                                    ...myItem,
                                                    ...other,
                                                });
                                            }}
                                            src={other.url.replace(
                                                "/sm",
                                                "/mini"
                                            )}
                                            className="w-10 h-10"
                                        />
                                    </div>
                                ))}
                        </div>
                        {props.item !== myItem && (
                            <Link
                                href={`/collection/${kebabCase(myCollection)}`}
                            >
                                <a target="_blank">
                                    <div
                                        className="cursor-pointer absolute bottom-24 left-28 text-center text-white font-medium flex items-center justify-center p-2 rounded-md backdrop-blur-md text-xs hover:text-gray-200"
                                        style={{
                                            textShadow: "1px 1px 0px #00000030",
                                            backgroundColor: "rgba(0,0,0,0.5)",
                                        }}
                                    >
                                        <div>
                                            <RectangleStackIcon
                                                style={{
                                                    filter: "drop-shadow(1px 1px 0px #00000030)",
                                                }}
                                                className="w-3 h-3 mr-2 drop-shadow-sm"
                                            />
                                        </div>
                                        <div>
                                            {myCollection || props.collection}
                                        </div>
                                    </div>
                                </a>
                            </Link>
                        )}
                    </>
                )}
            </div>

            <div className="sm:pl-4 flex-grow flex-shrink flex flex-col bg-gray-100 dark:bg-gray-900 border-gray-200 dark:border-gray-800 border-t sm:border-l p-3 sm:p-6">
                <div className="flex-grow flex-shrink">
                    <p className="pb-6 sm:pt-0 sm:pb-2 text-gray-700 dark:text-gray-100 font-light">
                        {_.capitalize(myItem.description)}
                    </p>
                </div>
                <div className="justify-end">
                    <div className="mt-2 focus:outline-none flex">
                        <a
                            href={`/edit/${myItem.image}`}
                            target="_blank"
                            rel="noreferrer"
                            className="focus:outline-none flex-1 mr-1"
                        >
                            <button
                                type="button"
                                className="items-center inline-flex w-full ring-0 outline-none justify-center rounded-md border border-gray-300 bg-white dark:bg-gray-800 pl-4 px-2 py-2 font-medium text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-100 dark:hover:bg-gray-700 dark:border-0 focus:outline-none sm:col-start-1 sm:mt-0 text-xs"
                            >
                                <PaintBrushIcon className="w-3 h-3" />
                                <div className="flex-1">Edit image</div>
                            </button>
                        </a>
                        <a
                            href={`/create?prompt=${encodeURIComponent(
                                myItem.description
                            )}${
                                myCollection || collection
                                    ? `&collection=${encodeURIComponent(
                                          myCollection || collection
                                      )}`
                                    : ""
                            }`}
                            target="_blank"
                            rel="noreferrer"
                            className="focus:outline-none flex-1 ml-1"
                        >
                            <button
                                type="button"
                                className="items-center inline-flex w-full ring-0 outline-none justify-center rounded-md border border-gray-300 bg-white dark:bg-gray-800 pl-3 px-2 py-2 font-medium text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-100 dark:hover:bg-gray-700 dark:border-0 focus:outline-none sm:col-start-1 sm:mt-0 text-xs"
                            >
                                <span className="text-lg mr-1 sparkle w-3">
                                    <img
                                        alt="Create similar"
                                        src="/sparkle-black.svg"
                                        className="dark:invert"
                                    />
                                </span>
                                <div className="flex-1">Create similar</div>
                            </button>
                        </a>
                    </div>
                    <div className="w-full mt-3">
                        <DownloadButton
                            onlySvg={props.onlySvg}
                            loading={isDownloadingSVG || isDownloadingPNG}
                            onDownloadSvg={() => downloadSVG()}
                            onDownloadPng={() => downloadPNG()}
                        />
                    </div>
                    {(props.hasPrev?.() || props.hasNext?.()) && (
                        <div className="flex sm:hidden w-full mt-3">
                            <button
                                onClick={() => props.prev?.()}
                                disabled={!props.hasPrev?.()}
                                type="button"
                                className="group mr-1 items-center inline-flex w-full ring-0 outline-none justify-center rounded-md border border-gray-300 bg-white pl-3 px-2 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none sm:col-start-1 sm:mt-0 text-xs"
                            >
                                <ChevronLeftIcon className="group-disabled:text-gray-300 w-6" />
                            </button>
                            <button
                                onClick={() => props.next?.()}
                                disabled={!props.hasNext?.()}
                                type="button"
                                className="group ml-1 items-center inline-flex w-full ring-0 outline-none justify-center rounded-md border border-gray-300 bg-white pl-3 px-2 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none sm:col-start-1 sm:mt-0 text-xs"
                            >
                                <ChevronRightIcon className="group-disabled:text-gray-300  w-6" />
                            </button>
                        </div>
                    )}
                    <div className="mt-3 sm:hidden">
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-gray-200 px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:col-start-1 sm:mt-0 sm:text-sm"
                            onClick={() => props.setOpen(false)}
                            ref={cancelButtonRef}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function ItemDialog(props) {
    const [open, setOpen] = useState(true);
    const [showAuth, setShowAuth] = useState(false);
    const [showPremium, setShowPremium] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        if (!open) {
            props?.onClose();
        }
    }, [open, props, props.onClose]);

    useEffect(() => {
        const handleKey = (e) => {
            if (e.key === "Escape") {
                setOpen(false);
            } else if (e.keyCode == "37") {
                // left arrow
                props.prev?.();
                e.preventDefault();
                e.stopPropagation();
            } else if (e.keyCode == "39") {
                // right arrow
                props.next?.();
                e.preventDefault();
                e.stopPropagation();
            }
        };
        window.addEventListener("keydown", handleKey);

        return () => {
            window.removeEventListener("keydown", handleKey);
        };
    });
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                initialFocus={containerRef}
                as="div"
                className="relative z-10"
                ref={containerRef}
                onClose={() => {}}
                onClick={(event) => {
                    if (event.target?.tagName !== "BUTTON") {
                        setOpen(false);
                    }
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-900 dark:bg-gray-950 dark:bg-opacity-75 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="relative flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        {props.hasPrev?.() && (
                            <div
                                onClick={(e) => {
                                    props.prev?.();
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                className="sm:-ml-16 sm:mr-8 p-2 pl-1 pb-0 rounded-xl hover:bg-opacity-10 hover:bg-white cursor-pointer"
                            >
                                <button>
                                    <ChevronLeftIcon className="cursor-pointer hidden md:block w-8 text-white" />
                                </button>
                            </div>
                        )}
                        {/* <div className="flex flex-col"> */}
                        {/* <div className="-mt-16 hidden h-8 md:block text-left self-start"><div className="inline-block p-1 rounded-xl hover:bg-opacity-10 hover:bg-white cursor-pointer"><XMarkIcon className="cursor-pointer w-8 text-white" /></div></div> */}
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                                {showAuth ? (
                                    <div className="p-3 sm:p-6 ">
                                        <div className="mb-6 pb-2 border-b">
                                            <h1>
                                                Sign up to continue downloading
                                                for free
                                            </h1>
                                        </div>
                                        <Auth
                                            supabase={props.supabase}
                                            redirectTo={
                                                "https://vectorart.ai/?redirect=/" +
                                                (props.item?.image || "")
                                            }
                                            onSignedIn={() =>
                                                setShowAuth(false)
                                            }
                                        />
                                    </div>
                                ) : showPremium ? (
                                    <div className="py-5">
                                        <PremiumDialogBody
                                            onClose={() =>
                                                setShowPremium(false)
                                            }
                                            title="Purchase a subscription to continue downloading"
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <Item
                                            {...props}
                                            open={open}
                                            setOpen={setOpen}
                                            onAuthNeeded={() =>
                                                setShowAuth(true)
                                            }
                                            onPremiumNeeded={() =>
                                                setShowPremium(true)
                                            }
                                        />
                                    </div>
                                )}
                            </Dialog.Panel>
                        </Transition.Child>
                        {/* </div> */}
                        {props.hasNext?.() && (
                            <div
                                onClick={(e) => {
                                    props.next?.();
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                className="sm:-mr-16 sm:ml-8 p-2 pb-0 pr-1 rounded-xl hover:bg-opacity-10 hover:bg-white cursor-pointer"
                            >
                                <button>
                                    <ChevronRightIcon className="cursor-pointer hidden md:block w-8 text-white" />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
