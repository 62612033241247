import { Transition } from "@headlessui/react";
import { useState } from "react";

export default function CopyLinkButton(props) {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltip, setTooltip] = useState("Copy Link");
    const [showCheckmark, setShowCheckmark] = useState(false);
    const tooltipOriginX = props.tooltipPosition === "bottom" ? 0 : 40;
    const tooltipOriginY = props.tooltipPosition === "bottom" ? 40 : 6;
    return (
        <>
            <button
                onClick={(e) => {
                    navigator.clipboard.writeText(props.link);
                    setShowTooltip(true);
                    setShowCheckmark(true);
                    setTooltip("Link Copied!");
                    setTimeout(() => {
                        setShowTooltip(false);
                    }, 1000);
                    setTimeout(() => {
                        setShowCheckmark(false);
                        setTooltip("Copy Link");
                    }, 2000);
                    window.umami?.track("Copy Link Button - Copy link", {
                        link: props.link,
                    });
                    e.stopPropagation();
                }}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                type="button"
                className="items-center inline-flex w-full ring-0 outline-none justify-center rounded-md border border-gray-300 bg-white bg-opacity-90 px-2 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none sm:col-start-1 sm:mt-0 text-xs dark:bg-gray-800 dark:text-gray-100 dark:hover:bg-gray-700 dark:border-gray-700"
            >
                <span className="text-lg mr-1 sparkle w-3">
                    {showCheckmark ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="w-4 h-4 text-green-600"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                            />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="w-4 h-4"
                        >
                            <path d="M12.232 4.232a2.5 2.5 0 013.536 3.536l-1.225 1.224a.75.75 0 001.061 1.06l1.224-1.224a4 4 0 00-5.656-5.656l-3 3a4 4 0 00.225 5.865.75.75 0 00.977-1.138 2.5 2.5 0 01-.142-3.667l3-3z" />
                            <path d="M11.603 7.963a.75.75 0 00-.977 1.138 2.5 2.5 0 01.142 3.667l-3 3a2.5 2.5 0 01-3.536-3.536l1.225-1.224a.75.75 0 00-1.061-1.06l-1.224 1.224a4 4 0 105.656 5.656l3-3a4 4 0 00-.225-5.865z" />
                        </svg>
                    )}
                </span>
            </button>
            <Transition.Root
                style={{
                    left: tooltipOriginX,
                    top: tooltipOriginY,
                }}
                className="hidden md:block absolute pointer-events-none top-8"
                show={showTooltip}
            >
                <Transition.Child
                    className={
                        "whitespace-nowrap backdrop-blur-sm bg-black bg-opacity-70 text-white font-medium text-xs rounded-lg px-4 p-1"
                    }
                    enter="transition-all ease-in-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-all ease-in-out duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    {tooltip}
                </Transition.Child>
            </Transition.Root>
        </>
    );
}
